<template>
  <div>
    <NavTitle class="mb16" title="商家主页"></NavTitle>
    <div class="panel mt16">
      <PanelTitle title="商家信息" :divided="false"/>
      <div class="pl20 pr20" v-if="detailInfo">
        <div class="flex">
          <div class="avatar">
            <LoadImage :src="detailInfo.avatar" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
          </div>
          <div class="flex flex-col justify-between">
            <div class="flex items-center">
              <span class="name">{{detailInfo.name}}</span>
              <div class="tag-identify">{{identifyTypes[detailInfo.identityType]}}</div>
            </div>
            <div class="fs14">
              <span>{{vipTypes[detailInfo.vipType]}}</span>
              <span v-if="detailInfo.vipType != 4">({{detailInfo.vipStartTime}} ~ {{detailInfo.vipExpireTime}})</span>
            </div>
            <div class="ent-row" v-if="detailInfo.identityType == 2" @click="$refs.entPopupRef.open({entId: detailInfo.entId})">
              <div>认证企业：{{detailInfo.entName}}</div>
              <img src="@/assets/images/arrow-right.png">
            </div>
          </div>
        </div>
        <div class="flex items-center mt16">
          <div class="btn-fill">
            <span>手机号：{{detailInfo.phone}}</span>
            <img class="pointer" src="@/assets/images/icon-copy.png" @click="handleCopy(detailInfo.phone)">
          </div>
        </div>
        <div class="flex items-center mt16">
          <div class="btn-balance">
            <!-- <img src="@/assets/images/icon-bmd.png"> -->
            <span>报名单余额：{{detailInfo.enrollFormBalance}}</span>
          </div>
          <div class="btn-balance ml40">
            <!-- <img src="@/assets/images/icon-dianquan.png"> -->
            <span>点券余额：{{detailInfo.ticketBalance}}</span>
          </div>
          <div class="btn-balance ml40">
            <!-- <img src="@/assets/images/icon-dianquan.png"> -->
            <span>保证金余额：{{detailInfo.bailBalance}}</span>
          </div>
        </div>
      </div>
      <PanelTitle title="报名单消耗" :divided="false"/>
      <div class="pl20 pr20">
        <el-select v-model="queryType" placeholder="选择查询方式" @change="getConsumeDetail">
          <el-option
            v-for="item in queryTypes"
            :key="item.type"
            :label="item.text"
            :value="item.type">
          </el-option>
        </el-select>
        <el-date-picker
          style="margin-left: 10px;"
          v-model="screenDate"
          value-format="yyyy-MM-dd"
          @change="getConsumeDetail">
        </el-date-picker>
        <div class="mt10">当{{queryType == 1 ? '日':'月'}}消耗数量：{{formConsume}}</div>
      </div>
    </div>
    <div class="pl20 bold fs20 mt16">发布的岗位</div>
    <div class="pl20 pr20">
      <PostList :list="postList"></PostList>
      <!-- 分页 -->
      <div class="flex justify-center" v-if="postList && postList.length > 0">
        <Pagination :currentPage="curPage + 1" @next-click="nextPage" @prev-click="prevPage"></Pagination>
      </div>
    </div>
    <!-- 商家信息弹窗 -->
    <EnterprisePopup ref="entPopupRef"></EnterprisePopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import Pagination from '@/components/Pagination'
import PostList from '@/components/PostList'
import EnterprisePopup from './components/EnterprisePopup.vue'
import { merchantInfoApi, theirPostsApi } from '@/api/admin.js'
import { copyUtil, dateFormatUtil } from '@/utils/util.js'
export default {
  name: 'MerchantDetail',
  components: {
    NavTitle,
    PanelTitle,
    LoadImage,
    Pagination,
    PostList,
    EnterprisePopup
  },
  data() {
    return {
      mid: this.$route.query.mid,
      detailInfo: null,
      postList: [],
      identifyTypes: {
        0: '未认证',
        1:'个人认证',
        2:'企业认证', 
        3:'个人认证审核中',
        4:'企业认证审核中',
        5:'个人认证审核已驳回',
        6:'企业认证审核已驳回'
      },
      vipTypes: {
        0:'普通会员',
        1:'青铜会员',
        2:'白银会员',
        3:'黄金会员',
        4:'会员已过期'
      },
      queryTypes: [
        {text: '按月', type: 0},
        {text: '按日', type: 1},
      ],
      queryType: 1,
      curPage: 0,
      screenDate: dateFormatUtil(),
      formConsume: 0
    }
  },
  created() {
    this.getMerchantInfo()
    this.getPostList()
    this.getConsumeDetail()
  },
  methods: {
    getMerchantInfo() {
      const formData = {
        mid: this.mid
      }
      merchantInfoApi(formData).then(res => {
        console.log('商户信息', res)
        this.detailInfo = res.data
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    getPostList() {
      const formData = {
        type: 1,
        uid: this.mid,
        startPage: this.curPage
      }
      theirPostsApi(formData).then(res => {
        console.log('发布职位', res)
        const list = res.data.auditPostList || []
        if(list.length != 0 || this.curPage == 0) {
          this.postList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleCopy(val) {
      copyUtil(val).then(() => {
        this.$tips({message: '复制成功', type: 'success'})
      })
    },
    nextPage() {
      this.curPage++
      this.getPostList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getPostList()
    },
    getConsumeDetail() {
      console.log('date', this.screenDate)
      if(!this.screenDate) return this.formConsume = 0
      const arr = this.screenDate.split('-')
      const formData = {
        mid: this.mid,
        type: 3
      }
      if(this.queryType == 0) {
        Object.assign(formData, {
          queryYear: arr[0],
          queryMonth: arr[1],
        })
      }
      if(this.queryType == 1) {
        Object.assign(formData, {
          queryYear: arr[0],
          queryMonth: arr[1],
          queryDay: arr[2],
        })
      }
      console.log('formData', formData)
      merchantInfoApi(formData).then(res => {
        console.log('报名单消耗', res)
        this.formConsume = res.data.formConsume || 0
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.avatar {
  width: 78px;
  height: 78px;
  border-radius: 12px;
  margin-right: 12px;
  border-radius: 39px;
  overflow: hidden;
}
.name {
  font-size: 16px;
  font-weight: bold;
}
.tag-identify {
  height: 26px;
  line-height: 26px;
  background-color: #FF9419;
  padding: 0 12px;
  border-radius: 13px;
  color: #fff;
  margin-left: 10px;
  font-size: 14px;
}
.ent-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
}
.btn-fill {
  display: flex;
  align-items: center;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  height: 34px;
  border-radius: 17px;
  padding: 0 12px;
  img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}
.btn-balance {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  background-color: #F3F7F8;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
</style>